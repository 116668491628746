import React, {useState } from 'react';
import Slider from "react-rangeslider";
import { setRateOfReturnYou } from "../../../../../store/actions/investorProfileActions";
import { connect } from "react-redux";
import classes from './RateOfReturnYouComp.module.scss'
import 'react-rangeslider/lib/index.css'

const RateOfReturnYouComp = ( props ) => {
    const [ value, setValue ] = useState( props.rateOfReturnYou || 7.20);
    const horizontalLabels = {
        8.7: `8.7%`,
        0: `0%`
    }

    const onChangeRangeHandler = value => {
        const val = Number(value.toFixed(2))
        props.setRateOfReturnYou(val)
        setValue(val)
    }

    const format = val => parseFloat(val).toFixed(2) + '%'
    return (
        <React.Fragment>
            <div className="sliderWrap">
                <Slider
                    min={0}
                    max={8.7}
                    step={0.01}
                    value={value}
                    tooltip={false}
                    handleLabel={`${value}%`}
                    format={format}
                    labels={horizontalLabels}
                    onChange={val => onChangeRangeHandler(val)}
                />
            </div>
            <div className={classes.RateDescription}>
                <h3>Rate of Return from 2000 to 2019</h3>
                <p>* The Rate of Return from 2000 to 2019 for the Growth profile is 7.20% consisting of 80% S&P/TSX Composite Total Return Index and 20% FTSE TMX Canada Universe Bond Index. Past returns are not necessarily indicative of future returns.</p>
            </div>
        </React.Fragment>

    )
};

function mapStateToProps(state) {
    return {
        rateOfReturnYou: state.investorProfile.rateOfReturnYou,
    }
};

function mapDispatchToProps(dispatch) {
    return {
        setRateOfReturnYou: val => dispatch(setRateOfReturnYou(val))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RateOfReturnYouComp);