import React, { useEffect } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import RateOfReturnYouComp
    from "../../../components/ContentRow/Step2/RateOfReturn/RateOfReturnYouComp/RateOfReturnYouComp";
import { secondStepCompleted } from "../../../store/actions/investorProfileActions";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const RateOfReturnYou = ( props ) => {
    const titles = {
        layoutTitle: 'YOUR INVESTOR PROFILE',
        sectionTitle: 'This tool uses a historical rate of return for a Growth profile for future projections.',
    }

    const title = "Rate of return based on your retirement profile"

    const next = props.isSpouse
        ? retirementCalcLinks.rateOfReturnSpouse
        : retirementCalcLinks.investorProfileSummary;

    useEffect(() => {
        props.secondStepCompleted(false)
    }, [props])

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep2={(7/9)*100}
            active={'step2'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='rateOfReturn'
                sectionTitle={titles.sectionTitle}
            >

                <InnerLayoutStepContent type={'you'}>
                    <RateOfReturnYouComp/>
                </InnerLayoutStepContent>

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        secondStepCompleted: (val) => dispatch(secondStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RateOfReturnYou);